import React from 'react';
import { flex, combine, txt, bg } from '../../style/utils';

import WrappedImage from '../WrappedImage/WrappedImage';

import featureBg from './feature-bg-sparks.png';
import styles from './FeatureCard.module.scss';

const FeatureCard = ({ icon, title, text }) => (
  <div
    className={combine(styles.card, bg.white, flex.col, flex.aic, txt.center)}
  >
    <WrappedImage
      wrapperClass={styles.iconWrapper}
      className={combine(styles.icon, styles[`icon__${icon.key}`])}
      src={icon.url}
      alt={icon.alt}
      style={{
        backgroundImage: `url(${featureBg})`,
        backgroundSize: '80%',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    />
    <h6 className={txt.bold}>{title}</h6>
    <p className={txt.lightGrey}>{text}</p>
  </div>
);

export default FeatureCard;
