import React from 'react';
import { combine, flex, txt, bg, border } from '../../style/utils';

import WrappedImage from '../WrappedImage/WrappedImage';

import styles from './BenefitsGrid.module.scss';

import icons from './icons';

const gridItems = [
  {
    icon: {
      url: icons.piggy,
      alt: 'Piggy bank icon',
    },
    title: 'One price for digital signage perfection.',
    text:
      'Hardware, software and support. We provide everything you need to get' +
      ' Flare working with your new or existing screens. ',
  },
  {
    icon: {
      url: icons.fresh,
      alt: 'Icon depicting fresh content',
      key: 'fresh',
    },
    title: 'Keep your content fresh and relevant.',
    text:
      'Every day is different, so we made sure changing your messaging only' +
      ' takes moments.',
  },
  {
    icon: {
      url: icons.trend,
      alt: 'Upwards trend icon',
    },
    title:
      'Boost morale, improve best practice, and increase customer or ' +
      'employee engagement. ',
    text:
      'Say what you want and share the things that matter with content ' +
      'management and control.',
  },
  // {
  //   icon: {
  //     url: icons.location,
  //     alt: 'Man standing on a target icon',
  //   },
  //   title: 'Involve viewers in your content generation. ',
  //   text: 'Curate and display social media feeds. ',
  // },
  {
    icon: {
      url: icons.cogs,
      alt: 'Cogs icon',
    },
    title: 'Simple installation anyone can manage.',
    text:
      'Anyone can set up Flare by plugging a small box into the HDMI port in' +
      ' the back of your screen, so your technical team can take a long lunch. ',
  },
  {
    icon: {
      url: icons.switch,
      alt: 'Icon showing two squares switching places',
    },
    title: 'Spotted a typo? Switch it swiftly with real time changes. ',
    text:
      'Just open up Flare, make the changes in the easy-to-use interface,' +
      " then click 'Update'. ",
  },
  {
    icon: {
      url: icons.timer,
      alt: 'Egg timer icon',
    },
    title: 'Down time? No such thing.',
    text:
      "With 99.99% availability and offline playback, you won't need to" +
      ' worry about digital signage downtime. ',
  },
  {
    icon: {
      url: icons.location,
      alt: 'Man standing on a target icon',
    },
    title: 'Pull online information your readers want to see. ',
    text:
      'Weather. Exchange rates. Social media. The latest news. ' +
      'We can pull in the data ' +
      "to give your readers what they're looking for. ",
  },
  {
    icon: {
      url: icons.chat,
      alt: 'Chat bubbles icon',
    },
    title: 'Manage your messaging.',
    text:
      'Structure and schedule content, set display periods, and enter start' +
      ' and stop dates to really tailor the messages your digital signs share.',
  },
];

// Create refs for these so we can reference the DOM elements later for the
// animations.
gridItems.forEach(i => {
  i.refs = {
    container: React.createRef(),
    overviewTitle: React.createRef(),
    detailTitle: React.createRef(),
  };
});

class BenefitsGrid extends React.Component {
  constructor(props) {
    super(props);
    this.raf = [];
  }
  animateTo(item, i) {
    cancelAnimationFrame(this.raf[i]);
    this.raf[i] = requestAnimationFrame(() => {
      const titleFrom = item.refs.overviewTitle.current;
      const titleTo = item.refs.detailTitle.current;
      const posFrom = titleFrom.getBoundingClientRect();
      const posTo = titleTo.getBoundingClientRect();
      const dx = posTo.x - posFrom.x;
      const dy = posTo.y - posFrom.y;
      const mx = posTo.width / posFrom.width;
      const my = posTo.height / posFrom.height;
      const transform = `translate(${dx}px, ${dy}px) scaleX(${mx}) scaleY(${my})`;
      titleFrom.style.transform = transform;
      item.refs.container.current.classList.add(styles.hidden);
    });
  }
  animateFrom(item, i) {
    cancelAnimationFrame(this.raf[i]);
    this.raf[i] = requestAnimationFrame(() => {
      const titleFrom = item.refs.overviewTitle.current;
      titleFrom.style.transform = '';
      item.refs.container.current.classList.remove(styles.hidden);
    });
  }
  render() {
    return (
      <div
        className={combine(styles.wrapper, flex.row, flex.wrap, styles.wrapper)}
      >
        {gridItems.map((item, i) => (
          <div
            key={i}
            className={combine(
              styles.item,
              flex.col,
              flex.jcc,
              flex.aic,
              txt.center,
              i == 2 ? styles.selected : null
            )}
            onMouseEnter={this.animateTo.bind(this, item, i)}
            onMouseLeave={this.animateFrom.bind(this, item, i)}
          >
            <div
              ref={item.refs.container}
              className={combine(
                styles.container,
                styles.overviewContainer,
                flex.col,
                flex.aic,
                flex.ass,
                flex.jcc,
                flex[1],
                [0, 2, 5, 7, 8].indexOf(i) !== -1 ? bg.darkerPurple : bg.purple
              )}
            >
              <WrappedImage
                wrapperClass={combine(
                  styles.iconWrapper,
                  flex[0],
                  styles[`icon__${item.icon.key}`]
                )}
                className={combine(styles.icon)}
                src={item.icon.url}
                alt={item.icon.alt}
              />
              <h6
                className={combine(styles.title, txt.white)}
                ref={item.refs.overviewTitle}
              >
                {item.title}
              </h6>
            </div>
            <div
              className={combine(
                styles.container,
                flex.col,
                flex.aic,
                flex.jcc,
                styles.detail,
                bg.white
              )}
            >
              <h6 className={txt.purple} ref={item.refs.detailTitle}>
                {item.title}
              </h6>
              <hr className={combine(styles.divider, border.blue)} />
              <p>{item.text}</p>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default BenefitsGrid;
