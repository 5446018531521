import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import { combine, bg, txt, flex } from '../style/utils';
import { getImage } from '../utils/queryUtils';

// Components
import HeroSpace from '../components/HeroSpace/HeroSpace';
import ContentWrapper from '../components/ContentWrapper/ContentWrapper';
import ActionButton from '../components/ActionButton/ActionButton';
import BenefitsGrid from '../components/BenefitsGrid/BenefitsGrid';
import FeatureCard from '../components/FeatureCard/FeatureCard';

import styles from './flare.module.scss';
import indexStyles from './index.module.scss';

import intelligentIcon from '../components/RadialFeatureList/icons/plugplay.png';
import focusedIcon from '../../assets/pages/flare/focused-icon.png';
import templateIcon from '../../assets/pages/flare/template-icon.png';

const easeInOutCubic = t =>
  t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;

const Flare = ({ data }) => {
  const features = [
    {
      icon: {
        url: intelligentIcon,
        alt: 'Intelligent display icon',
        key: 'intelligent',
      },
      title: 'Intelligent Display',
      text:
        'Using Flare, your designs will display perfectly on any size screen,' +
        ' in moments, and without even opening a photo editing tool. ',
    },
    {
      icon: {
        url: templateIcon,
        alt: 'Template icon',
        key: 'template',
      },
      title: 'Choose Any Template',
      text:
        'Choose one of the beautiful preloaded templates, fill in the gaps, ' +
        'and see your design on screen.',
    },
    {
      icon: {
        url: focusedIcon,
        alt: 'Focused experience icon',
        key: 'focused',
      },
      title: 'Focused Experience',
      text:
        'Manage what you’re saying and when you say it. Set start times,' +
        ' end times, and rotation frequencies for a truly viewer-focused' +
        ' experience.',
    },
  ];
  const steps = [
    {
      text: 'Log in to Flare',
      detail: '',
    },
    {
      text: 'Select your template',
      detail: '',
    },
    {
      text: 'Upload an image or video you want to share',
      detail: '',
    },
    {
      text: 'Add a dynamic feed',
      detail: '',
    },
    {
      text: 'Type in your message',
      detail: '',
    },
    {
      text: 'Adjust the layout for the look you want',
      detail: '',
    },
    {
      text:
        'Decide when each message is shown, how long for, and how often it' +
        ' should appear',
      detail: '',
    },
    {
      text: 'Got a question? Chat online with our technical team',
      detail: '',
    },
    {
      text: 'Hit update',
      detail: '',
    },
    {
      text: 'See your content live!',
      detail: '',
    },
  ];
  return (
    <div>
      <HeroSpace
        title="Flare Digital Signage"
        subtitle="Digital signage software for professional results, in moments."
        image={{
          fluid: data.heroImage.fluid,
          alt:
            'Graphic showing a state of the art digital signage system and content management interface.',
          className: styles.heroGraphic,
        }}
        // button={{
        //   text: 'Learn more',
        //   link: '/contact',
        //   alt: 'Flare digital signage product showcase',
        //   className: styles.heroButton,
        // }}
        extraChildren={
          <div className={combine(flex.col, flex.ass, txt.center)}>
            <div
              className={combine(
                styles.featureCardWrapper,
                flex.ass,
                flex.jcsa,
                flex.jcse,
                flex.row,
                flex[1]
              )}
            >
              {features.map((feat, i) => (
                <FeatureCard key={i} {...feat} />
              ))}
            </div>
            {/* <ActionButton
              className={combine(flex.asc, styles.readMoreButton)}
              onClick={() => {
                const startY = window.scrollY;
                const startT = Date.now();
                const target = 750;
                const duration = 500;
                const frameMovement = function() {
                  const dt = Date.now() - startT;
                  const dy = easeInOutCubic(dt / duration) * target;
                  window.scrollTo(0, dy + startY);
                  if (target - dy >= 2) {
                    requestAnimationFrame(frameMovement);
                  }
                };
                window.requestAnimationFrame(frameMovement);
              }}
              icon="readmore"
              variant="white"
            /> */}
            <h5 className={combine(txt.grey, styles.moreReasonsMobile)}>
              More reasons to use Flare
            </h5>
          </div>
        }
      />

      <ContentWrapper
        backgroundClasses={bg.lightBlue}
        variant={[
          'fullWidthMobile',
          'noVerticalPaddingMobile',
          'reducedPadding',
        ]}
      >
        <h5
          className={combine(styles.moreReasonsDesktop, txt.purple, txt.center)}
        >
          More reasons to use Flare
        </h5>
        <BenefitsGrid />
      </ContentWrapper>

      <ContentWrapper backgroundClasses={bg.lightBlue} variant="narrow">
        <h5 className={combine(txt.center, txt.purple)}>How Flare works</h5>
        {steps.map((step, i) => (
          <div
            key={i}
            className={combine(
              styles.usingFlareStep,
              i % 2 ? flex.row : flex.rowReverse,
              flex.aic
            )}
          >
            <Img
              className={combine(
                styles.flareStepImage,
                styles[`flareStepImage__${i + 1}`]
              )}
              {...getImage(data.stepImages, `step-${i + 1}.png`)}
              alt={step.text}
            />
            <h6
              className={combine(
                flex[1],
                // txt.center,
                styles.flareStepText,
                i % 2 ? txt.left : txt.right,
                styles[`flareStepText__${i + 1}`]
              )}
            >
              {step.text}
            </h6>
          </div>
        ))}
      </ContentWrapper>
      <ContentWrapper variant="dark">
        <div className={combine(txt.center, flex.col, flex.aic)}>
          <h3>Start a free trial of Flare today</h3>
          <h4>No credit card required</h4>
          <ActionButton
            className={indexStyles.seeItInActionButton}
            variant="secondary"
            modifiers={['hero']}
            link="/contact?type=trial"
          >
            Request a Trial
          </ActionButton>
        </div>
      </ContentWrapper>
    </div>
  );
};

export default Flare;

export const pageQuery = graphql`
  query flareAssets {
    stepImages: allImageSharp(
      filter: { fluid: { originalName: { regex: "/step-\\d+[.]png/" } } }
    ) {
      edges {
        node {
          fixed(width: 536, quality: 85) {
            name: originalName
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
    heroImage: imageSharp(
      fluid: { originalName: { regex: "/flare-hero[.]png/" } }
    ) {
      fluid(maxWidth: 1330, traceSVG: { color: "#ffffff" }, quality: 85) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;
